import { useLanguage } from '~/components/Language/LanguageProvider'

interface AuthLayoutBoxProps {
    /**
     * Title
     */
    title: string
    /**
     * Extend element
     */
    children?: JSX.Element | JSX.Element[]
}

const AuthLayoutBox = ({ children }: AuthLayoutBoxProps) => {
    const { t } = useLanguage()
    return (
        <div className="flex flex-col items-start mx-auto max-w-full" style={{ width: 552 }}>
            <div className="mb-4 flex justify-center w-full text-4xl font-bold">Kelola.co</div>
            <p className="text-center leading-snug font-medium text-neutrals-700 text-lg w-full mb-5 whitespace-pre">
                {t('common.trusted_by.title')}
            </p>
            <div className="bg-white rounded-2xl px-4 flex flex-col items-center w-full">
                <div className="w-96 max-w-full mx-auto">{children}</div>
            </div>
        </div>
    )
}

export default AuthLayoutBox
